/**
 * External dependencies.
 */
import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import classnames from "classnames"
import { sanitize } from "../../utils/functions"

/**
 * Internal dependencies.
 */
import Image from "./../image/index"
import { formatNewsDate } from "../../utils/functions"
import ArrowBigRight from "./../icons/long-arrow-right"

import "./style.scss"

/**
 * BoxArticle Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const BoxArticle = ( { post, animate = false } ) => {

	if ( isEmpty( post.featuredImage.node ) || isEmpty( post.featuredImage.node.sourceUrl ) ) {
		post.featuredImage.node = {
			sourceUrl: ''
		};
	}

	if ( isEmpty( post.readMore ) ) {
		post.readMore = 'Read More';
	}

	const { title, excerpt, featuredImage, date, readMore } = post;

	if ( ! title ) {
		return null;
	}

	const wrapperClass = classnames(
		'box-article-card',
		{
			'animated slideInUp': animate
		},
		{
			'has-content': ! isEmpty( excerpt )
		}
	);

	return (
		<article className={ wrapperClass }>
			<div className="box-article-card__header">
				{
					featuredImage.node.sourceUrl ? (
						<figure className="box-article-card__figure">
							<Image lazy={ false } { ...featuredImage.node } className="box-article-card__image" />
						</figure>
					) : null
				}

				<h2 className="box-article-card__title">
					{ sanitize( title ) }
				</h2>

				{ excerpt ? (
					<div className="box-article-card__content" dangerouslySetInnerHTML={ { __html: sanitize( excerpt ) } } />
				) : null }
			</div>

			<div className="box-article-card__footer">
				{ date ? (
					<time className="box-article-card__posted-on">{ formatNewsDate( date ) }</time>
				) : null }

				<div className="box-article-card__read-more">
					<span className="box-article-card__read-more-text">{ readMore }</span> { ArrowBigRight( '#000' ) }
				</div>
			</div>

		</article>
	);
};

BoxArticle.propTypes = {
  post: PropTypes.object,
  animate: PropTypes.bool,
}

BoxArticle.defaultProps = {
  post: {
    title: "",
    excerpt: "",
    uri: "",
    featuredImage: {},
  },
  animate: false,
}

export default BoxArticle
