/**
 * External dependencies.
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get, isObject } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import BoxArticle from '../../box-article/index';
import { sanitize } from './../../../utils/functions';
import Link from './../../link';

import './style.scss';

/**
 * ArticleList Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const ArticleList = forwardRef(({ posts, animate = false, className, refIndex }, ref) => {

	if (isEmpty(posts) || !posts.length) {
		return null;
	}

	const containerClass = classnames('blog-article row', className);

	return (
		<div className={containerClass}>
			{posts.map((article, index) => {
				const post = {
					title: get(article, 'node[title]'),
					date: get(article, 'node[pressArticleMeta][date]'),
					featuredImage: get(article, 'node[featuredImage]'),
					uri: get(article, 'node[pressArticleMeta][articleLink]'),
					external: true,
					readMore: 'Go to article'
				};

				return (
					<div ref={index === refIndex ? ref : null} className="small-12 medium-6 column" key={article.node.id}>
						{isObject(post.uri) ?
							<Link {...post.uri} external={true} className="article__box-link" title={sanitize(post.title)}>
								<BoxArticle post={post} animate={animate} />
							</Link> :
							<Link url={post.uri} external={true} className="article__box-link" title={sanitize(post.title)}>
								<BoxArticle post={post} animate={animate} />
							</Link>
						}
					</div>
				);
			})}
		</div>
	);
});

ArticleList.propTypes = {
	posts: PropTypes.array,
	animate: PropTypes.bool,
	className: PropTypes.string
};

ArticleList.defaultProps = {
	posts: [],
	animate: true
};

export default ArticleList;
