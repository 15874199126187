/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { sanitize } from '../../utils/functions';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import LinkWithArrow from '../../components/link-with-arrow';
import PressArticles from '../../components/press-articles';
import Link from '../../components/link';
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";
import ContactForm from '@blocks/contact-form';

import './style.scss';

/**
 * MediaCoverage component.
 *
 * @param {Object} props Component Props
 *
 * @return {*}
 */
const MediaCoverage = (props) => {

	const { pageContext: { page: { uri, seo, title, content, newsRoomMeta }, pressArticles } } = props;
	const openGraphImage = getOgImage(seo);
	return (
		<Layout title={title} uri={uri} className="page newsroom-page">
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<div className="blog-header">
				<h1 className="blog-header__title blog-header__title--newsroom">{title}</h1>
				<PressArticles initialPosts={pressArticles} />
			</div>
			<div className="blog-links">
				{newsRoomMeta.downloadLink ? (
					<Link withArrow={true} external={true} {...newsRoomMeta.downloadLink} color="#000" />
				) : ''}
				{newsRoomMeta.pageLink ? (
					<LinkWithArrow text={newsRoomMeta.pageLinkTitle} link={newsRoomMeta.pageLink.uri} color="#000" />
				) : ''}
			</div>
			<div className="blog-footer" dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
			<ContactForm />
		</Layout>
	)
};

MediaCoverage.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default MediaCoverage;
