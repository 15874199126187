/**
 * External dependencies.
 */
import gql from 'graphql-tag';

const PRESS_ARTICLES = gql`
query PRESS_ARTICLES($first: Int, $last: Int, $after: String, $before: String) {
	pressArticles(first: $first, last: $last, after: $after, before: $before) {
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		edges {
			node {
				id
				title
				featuredImage {
					node {
						altText
						sourceUrl
						title
					}
				}
				pressArticleMeta {
					articleLink {
					target
					title
					url
					}
					date
					fieldGroupName
				}
			}
		}
	}
}

`;

export default PRESS_ARTICLES;
